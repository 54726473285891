.animation {
  padding: 15px;
  border: 1px solid #ddd;
  cursor: grab;
  border-radius: 5px;
  margin-top: 5px;

  animation: pulse 8.5s linear;
}
.animation .btn {
  display: flex;
  flex-direction: column;
  border: none;
  background: #ccc;
}

.animation .up {
  position: absolute;
  background: none;
  right: 4px;
  bottom: 12px;
}

.animation .down {
  position: absolute;
  background: none;
  right: 4px;
  bottom: 2px;
}

.animation img {
  width: 100%;
  height: 120px;
  box-shadow: 1px 3px 4px rgba(0, 0, 0, 0.4);
  border-radius: 5px;
}

.animation h1 {
  font-size: 15px;
  color: #555;
  text-transform: capitalize;

  display: flex;
  flex: 1;
  justify-content: center;
}

.animation input {
  width: 100%;
  margin: -100px auto 0 auto;
  height: 40px;
  border-radius: 2px;
  border: 1px solid #ddd;
  color: #312e38;
  background: #fff;
  padding: 0 12px;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgb(221, 65, 65);
  }
  100% {
    box-shadow: 0 0 0 10px rgba(221, 65, 65, 0);
  }
}
